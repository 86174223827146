<template>
    <div style="padding: 16px 16px 0 16px;">
        <q-tabs
            indicator-color="transparent"
            narrow-indicator
            dense
            align="justify"
            class="bg-white text-grey-9 shadow-2"
        >
            <q-tab  name="mails" icon="mail" label="ПРЕТЕНЗИИ" style="padding:1em"/>
            <q-tab  name="alarms" icon="gavel" label="ИСКОВЫЕ ЗАЯВЛЕНИЯ" style="padding:1em"/>
            <q-tab  name="movies" icon="movie" label="АПЕЛЛЯЦИИ" style="padding:1em"/>
        </q-tabs>
    </div>
</template>

<script>

export default {
    data () {
        return {
           
        }
    }
}
</script>