<template>
    <div>
        <status-info/>
        <table-cases/>
    </div>
</template>

<script>
import StatusInfo from '@/components/default/info.vue'
import TableCases from '@/components/cases/table.vue'
export default {
    components: { TableCases, StatusInfo },
    data () {
        return {
        }
    }
    
}
</script>